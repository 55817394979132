import contactFormValidator from '../validations/contact';

const errorMsg = document.querySelector('.js-submit-message[data-type="error"]');
const successMsg = document.querySelector('.js-submit-message[data-type="success"]');

function toggleLoading() {
  const submitBtn = document.querySelector('.js-submit-form');
  if (submitBtn) {
    submitBtn.querySelector('span').classList.toggle('u-hidden');
    submitBtn.querySelector('.js-svg-loading').classList.toggle('u-hidden');
  }
}

function handleValidation(data) {
  const result = contactFormValidator.validate(Object.fromEntries(data), {
    abortEarly: false,
  });
  const { error } = result;

  if (error && error.details.length > 0) {
    error.details.forEach((e) => {
      const errorMessage = document.querySelector(`.js-error-field[data-input="${e.context.key}"]`);
      if (errorMessage) {
        errorMessage.querySelector('span').innerHTML = e.message;
        errorMessage.classList.remove('u-hidden');
      }
    });

    return false;
  }

  toggleLoading();

  return true;
}

function handleFormResponse(form, response) {
  if (errorMsg && successMsg) {
    if (response.ok) {
      successMsg.classList.remove('u-hidden');
      errorMsg.classList.add('u-hidden');
    } else {
      successMsg.classList.add('u-hidden');
      errorMsg.classList.remove('u-hidden');
    }
  }

  form.reset();
}

function submitForm(event) {
  event.preventDefault();

  const { currentTarget } = event;
  const data = new FormData(currentTarget);

  document.querySelectorAll('.js-error-field').forEach((e) => {
    e.classList.add('u-hidden');
  });

  if (handleValidation(data)) {
    toggleLoading();
    // remove the consent property to not sending on the form
    data.delete('consent');

    fetch(FORMSPREE_CONTACT_FORM, {
      method: 'POST',
      body: data,
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => {
      handleFormResponse(currentTarget, response);
    }).catch(() => {
      if (errorMsg && successMsg) {
        successMsg.classList.add('u-hidden');
        errorMsg.classList.remove('u-hidden');
      }

      toggleLoading();
    });
  }
}

function init() {
  const form = document.getElementById('contact-form');

  if (form) {
    if (!FORMSPREE_CONTACT_FORM || FORMSPREE_CONTACT_FORM === '') {
      throw new Error('There is no formspree contact form id to continue, you must configure it on your env');
    } else {
      form.addEventListener('submit', submitForm);
    }
  }
}

export default init();
