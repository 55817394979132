import { isMobile } from '../modules/utils';

function toggle(event) {
  event.preventDefault();
  const data = event.currentTarget.dataset.toggle;
  const element = data === 'true' ? event.currentTarget : document.querySelector(`.${data}`);

  if (event.currentTarget.dataset.mobileOnly && !isMobile()) {
    return false;
  }

  if (!element.classList.contains('is-active')) {
    element.classList.add('is-active');
  } else {
    element.classList.remove('is-active');
  }

  return true;
}

function init() {
  const toggleElements = document.querySelectorAll('[data-toggle]');
  if (toggleElements) {
    toggleElements.forEach((el) => {
      el.addEventListener('click', toggle);
    });
  }
}

export default init();
