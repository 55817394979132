function toggleMenu(event) {
  const menu = document.querySelector('.js-menu');

  if (menu.classList.contains('is-active')) {
    menu.classList.remove('is-active');
    menu.previousElementSibling.classList.remove('is-active');
    menu.nextElementSibling.classList.remove('is-active');
    event.currentTarget.classList.remove('is-active');
    document.body.style.overflow = 'initial';
  } else {
    menu.classList.add('is-active');
    menu.previousElementSibling.classList.add('is-active');
    event.currentTarget.classList.add('is-active');
    document.body.style.overflow = 'hidden';
  }
}

function init() {
  const menuBtn = document.querySelectorAll('.js-menu-btn');
  if (menuBtn) {
    menuBtn.forEach((el) => {
      el.addEventListener('click', toggleMenu);
    });
  }
}

export default init();
