import Joi from 'joi';

const contactFormValidator = Joi.object({
  name: Joi
    .string()
    .label('Nombre')
    .required()
    .messages({
      'string.empty': '{#label} es requerido.',
    }),
  email: Joi
    .string()
    .label('Email')
    .required()
    .email({
      minDomainSegments: 2, tlds: { allow: false },
    })
    .messages({
      'string.empty': '{#label} es requerido.',
      'string.email': '{#label} no es valido.',
    }),
  parking: Joi
    .string()
    .label('Aparcamiento')
    .required()
    .messages({
      'string.empty': '{#label} es requerido.',
    }),
  message: Joi
    .string()
    .label('Mensaje')
    .required()
    .messages({
      'string.empty': '{#label} es requerido.',
    }),
  consent: Joi
    .string()
    .required()
    .messages({
      'any.required': 'Debe aceptar el consentimiento para continuar',
    }),
});

export default contactFormValidator;
