const device = {
  MOBILE: 0,
  LANDSCAPE: 1024,
  DESKTOP: 1280,
};

function getDocumentWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function isMobile() {
  return getDocumentWidth() < device.LANDSCAPE;
}

export function isLandscape() {
  return getDocumentWidth() >= device.LANDSCAPE && getDocumentWidth() < device.DESKTOP;
}

export function isDesktop() {
  return getDocumentWidth() >= device.DESKTOP;
}

export function getDevice() {
  if (isMobile()) {
    return 'MOBILE';
  }

  if (isLandscape()) {
    return 'LANDSCAPE';
  }

  return 'DESKTOP';
}
